import useWindowSize from '@/hooks/useWindowSize';
import MainLayout from '@/layouts/MainLayout';
import s from '@/modules/GamePage/styles.module.scss';
import React, { useEffect } from 'react';

interface IProps {
  iframeURL: string;
}

const Container = (props: IProps) => {
  const { heightWidth } = useWindowSize();
  const elmHeader = document.getElementById('header');

  useEffect(() => {
    window.focus();
    function blur(e: any) {
      const elmHeader = document.getElementById('header-hidden');
      if (elmHeader) {
        elmHeader.click();
      }
    }
    window.addEventListener('blur', blur);
    return () => window.removeEventListener('blur', blur);
  }, []);

  return (
    <MainLayout
      isWallet={true}
      hideFooter
      isLight={true}
      cssMain={false}
      hideBanner={true}
    >
      <div
        className={s.container}
        style={{
          height:
            heightWidth -
            (elmHeader && elmHeader.offsetHeight ? elmHeader.offsetHeight : 80),
        }}
      >
        <iframe
          id="game_iframe"
          src={props.iframeURL}
          width="100%"
          style={{ border: 'none' }}
        />
      </div>
    </MainLayout>
  );
};

export default Container;
