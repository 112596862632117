import configs from '@/constants/trustless.constant';
import Container from '@/pages/tc/container';

const PageContainer = () => {
  return <Container iframeURL={configs.APP_URL} />;
};

export async function getServerSideProps() {
  return {
    props: {
      seoInfo: {
        title: configs.META_DATA.title,
        description: configs.META_DATA.description,
        image: configs.META_DATA.image,
      },
    },
  };
}

export default PageContainer;
